import React from "react";

class Footer extends React.Component {
  render() {
    return (
      <footer>
        <div className="top-footer">
          <p className="legal_notice_title">&nbsp;</p>
          <p className="legal_disclaimer">&nbsp;</p>
        </div>
        <div className="bottom-footer">
          <p>© Johnson &amp; Johnson GmbH 2020</p>
          <p>
            Diese Website wird veröffentlicht von der Johnson &amp; Johnson
            GmbH, die ausschließlich für deren Inhalt verantwortlich ist. Die
            Website dient Besuchern aus Deutschland und Österreich.
          </p>
          <p>Letzte Änderung am 29/09/2020</p>
        </div>
      </footer>
    );
  }
}

export default Footer;
